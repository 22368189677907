<script setup lang="ts">

import {ref} from "vue";
import {getDatabaseTables, updateDatabaseTable} from "@/services/client";
import {useRoute} from "vue-router";
import {DatabaseTable} from "@/types";

const route = useRoute()
const tables = ref<DatabaseTable[]>([])
const databaseName = route.params.name as string


getDatabaseTables(databaseName)
    .then((t) => tables.value = t)


const updateTable = (table: DatabaseTable) => {
  console.log('update table')
  updateDatabaseTable(databaseName, table)
      .then((t) => {
        console.log(t)
      })
      .catch((e) => {
        console.log(e)
      })
}

</script>

<template>
  <div class="database-config">
    <h1 class="justify-content-between">Database Tables</h1>

    <table class="table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Is Selected</th>
        <th>Note</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="table in tables">
        <td>{{ table.table_name }}</td>
        <td>
          <input type="checkbox" v-model="table.is_selected">
        </td>
        <td>
          <textarea name="note" id="note" cols="30" rows="3" class="form-control" v-model="table.note "></textarea>
        </td>
        <td>
          <router-link :to="{name:'database table columns', params:{database:databaseName, tableId:table.id}}"
                       class=" btn btn-sm btn-primary mx-2">
            Columns
          </router-link>
          <button class=" btn btn-sm btn-info mx-2" @click.prevent="updateTable(table)">
            Save
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
