<script setup lang="ts">

import {ref} from 'vue'
import {useStore} from "vuex";
import {mutations} from "@/store";
import {getQueries} from "@/services/client";
import ExamplesListTable from "@/components/ExamplesListTable.vue";
import {Example} from "@/types";

const store = useStore()

const config = ref({
  identifier: store.getters.config('identifier'),
  prompt: store.getters.config('prompt'),
  database: store.getters.config('database')
})

const updateSettings = () => {
  store.commit(mutations.updateConfig, config.value)
  alert('config updated!')
}


const examples = ref<Example[]>([])

getQueries()
    .then((data: Example[]) => examples.value = data)


</script>

<template>
  <main class="col-12 examples">
    <h2>Examples</h2>
    <ExamplesListTable :examples="examples"/>
  </main>
</template>
