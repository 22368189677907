<script setup lang="ts">

import {onMounted, ref} from 'vue'
import {useStore} from "vuex";
import {actions} from "@/store";
import UserMessage from "@/components/UserMessage.vue";
import AssistantMessage from "@/components/AssistantMessage.vue";
import UserInput from "@/components/UserInput.vue";
import bootstrap from 'bootstrap'

const store = useStore()
const imageModal = ref<HTMLElement>()

onMounted(() => {
  store.dispatch(actions.getMessages)
})
const showImage = () => {
  console.log('showImage')
  document.querySelector('#overlay')?.classList.add('show')
}

const closeModal = () => {
  console.log('closeModal')
  document.querySelector('#overlay')?.classList.remove('show')
}

</script>

<template>
  <main class="col-12">
    <h2>Conversations</h2>
    <div id="conversation-container">
      <div v-for="message in store.state.messages" :key="message.id">
        <UserMessage v-if="message.role === 'user'" :message="message" @show-thumbnail="showImage"/>
        <AssistantMessage v-else :message="message"/>
      </div>
    </div>

    <UserInput/>


  </main>
</template>

