<script setup lang="ts">
import {computed, defineProps, nextTick, onMounted, ref, watch} from 'vue'
import showdown from 'showdown'
import {VueShowdown} from "vue-showdown";
import {Message} from "@/types";
import *  as echarts from 'echarts'
import {logger} from "@azure/openai/types/src/logger";
import ChartComponent from "./ChartComponent.vue";
import showdownKatex from 'showdown-katex'

type Props = {
  message: Message
}

const convertToHTML = (markdown: string) => {
  const converter = new showdown.Converter({
    extensions: [
      showdownKatex({
        displayMode: false,
        throwOnError: false, // allows katex to fail silently
        errorColor: '#ff0000',
        delimiters: [
          {left: "\[", right: "\]", latex: true, display: true},
        ],
      }),
    ],
  });
  return converter.makeHtml(markdown);
}

const convertedHTML = computed(() => {
  return convertToHTML(props.message.content)
})

const props = defineProps<Props>()

const chartContainer = ref(null)

const chatOptions = ref<any[]>([])

const showChart = ref(false)

watch(props.message, async (newValue: any) => {
      if (newValue.meta &&
          newValue.meta.toolName === 'chart_generator' &&
          newValue.meta.result &&
          !showChart.value) {
        chatOptions.value.push(newValue.meta.result)
      }
    }
)


onMounted(async () => {
  const meta = props.message.meta
  if (meta?.chat) {
    chatOptions.value.push(meta)
  } else if (Array.isArray(meta?.attachment)) {
    chatOptions.value = meta.attachment
  }
})


</script>

<template>
  <div class="card mb-3 assistant-message">
    <div class="row">
      <div style="width: 80px" class="d-flex justify-content-center align-content-center mt-3">
        <strong>Bot</strong>
      </div>

      <div class="col">
        <div class="chart my-3" ref="chartContainer" v-if="chatOptions && chatOptions.length">
          <ChartComponent v-for="(options, index) in chatOptions" :key="index"
                          :chart-options="options"></ChartComponent>
        </div>
        <div class="notification p-3 border-1 border-primary w-50 text-start border rounded m-3"
             v-if="message.meta?.notification"><strong>{{ message.meta?.notification }}</strong></div>
        <div class="card-body text-start" v-html="convertedHTML"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
table, th, td {
  border: 1px solid;
}

.katex-html{
  display: none;
}

.assistant-message .card-body {
  & table,
  & th,
  & td {
    border: 1px solid;
    padding: 5px
  }

  & table {
    max-width: 100%;
    overflow-x: scroll;
    margin-bottom: 1rem;
  }
}

</style>