<script setup lang="ts">

import {useStore} from "vuex";
import {computed} from "vue";

const store = useStore()

const databases = computed(() => store.getters.databases)


</script>

<template>
  <div class="database-config">
    <h1 class="justify-content-between">Database Config
      <router-link :to="{name:'new database'}" class="btn btn-sm btn-success">Add</router-link>
    </h1>

    <table class="table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Type</th>
        <th>Database</th>
        <th>Is Active</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="database in databases">
        <td>{{ database.name || "" }}</td>
        <td>{{ database.type || "MYSQL" }}</td>
        <td>{{ database.database_name || "" }}</td>
        <td>
          <span class="badge bg-primary" v-if=" database.is_active"> Active</span>
          <span class="badge bg-secondary" v-else> No</span>
        </td>
        <td>
          <router-link :to="{name:'database tables', params:{name:database.name || 'null'}}"
                       class="btn btn-sm btn-primary mx-2">
            Tables
          </router-link>
          <button class="btn btn-sm btn-primary mx-2">Edit</button>
        </td>

      </tr>
      </tbody>
    </table>


  </div>
</template>
