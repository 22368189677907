import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {VueShowdownPlugin} from 'vue-showdown';


import 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'


createApp(App)
    .use(store)
    .use(router)
    .use(VueShowdownPlugin, {
        options: {
            emoji: true,
            tables: true,
            tablesHeaderId: true,
        },
    })
    .mount('#app')
