import {createStore} from 'vuex'
import * as client from "../services/client";
import {Database, HeaderConfig, Message} from "../types";


type DeltaMessage = {
    deltaMessage: string
    meta?: {
        notification?: string | null
    }
}

export const mutations = {
    addMessage: "addMessage",
    updateMessage: "updateMessage",
    updateLatestMessage: "updateLatestMessage",
    removeLastMessage: "removeLastMessage",
    addTempUserMessage: "addTempUserMessage",
    emptyMessages: "emptyMessages",
    updateConfig: "updateConfig",
    setDatabases: "setDatabases",
}

export const actions = {
    getMessages: "getMessages",
    getDatabases: "getDatabases",
}

export default createStore({
    state: {
        messages: [] as Message[],
        databases: [] as Database[],
        config: {
            identifier: "crm_1",
            prompt: "",
            database: "crm",
        } as HeaderConfig
    },

    getters: {
        config: (state) => (key: string) => {
            return state.config[key]
        },
        databases: (state) => {
            return state.databases
        }
    },
    mutations: {
        [mutations.addMessage](state, message) {
            state.messages.push(message)
        },
        [mutations.updateMessage](state, message: Message) {
            const index = state.messages.findIndex(m => m.id === message.id)
            state.messages[index] = message
        },
        [mutations.updateLatestMessage](state, message: DeltaMessage) {
            const latest = state.messages[state.messages.length - 1]
            latest.content = latest.content + message.deltaMessage
            latest.meta = message.meta
        },
        [mutations.removeLastMessage](state) {
            state.messages.pop()
        },
        [mutations.addTempUserMessage](state, message: string) {
            // create random Id
            const id = Math.floor(Math.random() * 100000)
            state.messages.push({id, role: 'user', content: message})
        },
        [mutations.emptyMessages](state) {
            state.messages = []
        },
        [mutations.updateConfig](state, config: HeaderConfig) {
            state.config = config
        },
        [mutations.setDatabases](state, databases: Database[]) {
            state.databases = databases
        }
    },
    actions: {
        [actions.getMessages]({commit}, messages: Message[]) {
            client.getMessages()
                .then((msg: Message[]) => {

                    commit(mutations.emptyMessages)

                    for (const message of msg) {
                        commit(mutations.addMessage, message)
                    }
                })
                .finally(()=>{
                    const scrollHeight = document.body.scrollHeight;
                    window.scrollTo(0, scrollHeight);
                })
        },
        [actions.getDatabases]({commit}, messages: Message[]) {
            client.getDatabases()
                .then((dbs: Database[]) => commit(mutations.setDatabases, dbs))
        }
    },
    modules: {}
})
