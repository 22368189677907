<script setup lang="ts">

import {ref} from "vue";
import {getDatabaseTableColumns, getDatabaseTables} from "@/services/client";
import {useRoute} from "vue-router";

const route = useRoute()
const columns = ref<{
  id: number,
  column_name: string,
  type: string,
  relationship: string,
  is_nullable: boolean,
  is_selected: boolean,
  note?: string
}[]>([])
const databaseName = route.params.name as string
const tableId = route.params.tableId as string


getDatabaseTableColumns(databaseName, parseInt(tableId))
    .then((cols) => columns.value = cols)


</script>

<template>
  <div class="database-config">
    <h1 class="justify-content-between">Database Table Columns</h1>

    <table class="table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Type</th>
        <th>Relationship</th>
        <th>Is Nullable</th>
        <th>Is Selected</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="col in columns">
        <td>{{ col.column_name }}</td>
        <td>{{ col.type }}</td>
        <td>{{ col.relationship }}</td>
        <td>{{ col.is_nullable }}</td>
        <td>
          <span class="badge bg-primary" v-if=" col.is_selected"> Selected</span>
          <span class="badge bg-secondary" v-else> No</span>
        </td>
        <td>
          <router-link
              :to="{name:'edit database table column', params:{database:databaseName, tableId, columnId:col.id}}"
              class="btn btn-sm btn-primary ">Edit
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
