<script setup lang="ts">

import {ref} from "vue";
import {host} from "@/services/client";
// it has props that is a image file
// when created, it will try to upload the image to server
// there is a status to keep try the upload status, uploading, success and failed

const props = defineProps<{
  file: File
}>()
const emit = defineEmits(['remove-file', 'upload-success'])

const status = ref('uploading')

const docPreviewDataUrl = () => {
  // if file is an image file, return the data url
  if (props.file.type.startsWith('image/')) {
    return URL.createObjectURL(props.file)
  }
  // if file is not an image file, return a placeholder image
  return 'https://via.placeholder.com/150'
}

const file_path = ref('')
const formData = new FormData()
formData.append('file', props.file)
const url = `${host}/api/upload`
fetch(url, {
  method: "POST",
  body: formData
})
    .then(response => {
      if (response.ok) {
        status.value = 'success'
        status.value = 'success'
        response.json()
            .then(json_data => {
              file_path.value = json_data.path
              emit('upload-success', json_data.path)
            })
      } else {
        status.value = 'failed'
      }
    })
    .catch(() => {
      status.value = 'failed'
    })

const removeImage = () => {
  console.log(props.file.name)
  emit('remove-file', {
    name: props.file.name,
    path: file_path.value
  })
}


</script>

<template>
  <!--  This is a square component with 1rem border radius -->
  <div class="document-preview">
    <i class="fa fa-times delete-icon" @click.prevent="removeImage"></i>
    <img :src="docPreviewDataUrl()" alt="document preview"/>
    <div><small>{{ file.name }}</small></div>
    <!--    <div v-if="status === 'uploading'" class="status">Uploading...</div>-->
    <!--    <div v-else-if="status === 'success'" class="status success">Success</div>-->
    <!--    <div v-else class="status failed">Failed</div>-->
  </div>
</template>

<style scoped lang="scss">
.document-preview {
  position: relative;
  display: inline-block;
  padding: 10px;

  .delete-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    color: red;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    line-height: 20px;
    background-color: gray;
  }

  img {
    width: 100px;
    height: 100px;
    border-radius: 1em;
  }
}

</style>