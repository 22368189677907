<script setup lang="ts">

import {Database} from "@/types";
import {ref} from "vue";
import {postDatabase} from "@/services/client";

const newDatabase = ref<Omit<Database, 'id'>>({
  type: 'MySQL',
  name: null,
  database_name: null,
  user: null,
  password: null,
  host: null,
  port: null,
  is_active: false
})

const addDatabase = () => {
  console.log(newDatabase.value)
  postDatabase(newDatabase.value)
      .then((res) => {
        console.log("res: ", res)
      })
}

</script>

<template>
  <main class="col-12 configurations">
    <h2>New Database Configuration</h2>
    <div id="conversation-container">
      <form action="#" @submit.prevent="addDatabase">

        <div class="row g-3 mb-3 align-items-center">
          <div class="col-auto col-xl-3 col-md-4 col-5">
            <label for="type" class="col-form-label">Database Type</label>
          </div>
          <div class="col">
            <select name="type" id="type" class="form-control" v-model="newDatabase.type" required>
              <option value="MySQL">MySQL</option>
              <option value="Postgres">Postgres</option>
              <option value="MSSQL">MSSQL</option>
            </select>
          </div>
        </div>


        <div class="row g-3 mb-3 align-items-center">
          <div class="col-auto col-xl-3 col-md-4 col-5">
            <label for="name" class="col-form-label">Name</label>
          </div>
          <div class="col">
            <input type="text"
                   name="name"
                   id="name"
                   class="form-control"
                   placeholder="the name of the database"
                   v-model="newDatabase.name"
                   required/>
          </div>
        </div>

        <div class="row g-3 mb-3 align-items-center">
          <div class="col-auto col-xl-3 col-md-4 col-5">
            <label for="database_name" class="col-form-label">Database Name</label>
          </div>
          <div class="col">
            <input type="text"
                   name="database_name"
                   id="database_name"
                   class="form-control"
                   placeholder="my_database_name"
                   required
                   v-model="newDatabase.database_name"
            />
          </div>
        </div>

        <div class="row g-3 mb-3 align-items-center">
          <div class="col-auto col-xl-3 col-md-4 col-5">
            <label for="user" class="col-form-label">Database User</label>
          </div>
          <div class="col">
            <input type="text"
                   name="user"
                   id="user"
                   class="form-control"
                   placeholder="my_database_user"
                   required
                   v-model="newDatabase.user"/>
          </div>
        </div>

        <div class="row g-3 mb-3 align-items-center">
          <div class="col-auto col-xl-3 col-md-4 col-5">
            <label for="password" class="col-form-label">Database Password</label>
          </div>
          <div class="col">
            <input type="password"
                   name="password"
                   id="password"
                   class="form-control"
                   placeholder="my_database_password"
                   required
                   v-model="newDatabase.password"/>
          </div>
        </div>

        <div class="row g-3 mb-3 align-items-center">
          <div class="col-auto col-xl-3 col-md-4 col-5">
            <label for="host" class="col-form-label">Database Host</label>
          </div>
          <div class="col">
            <input type="text"
                   name="host"
                   id="host"
                   class="form-control"
                   placeholder="localhost"
                   required
                   v-model="newDatabase.host"/>
          </div>
        </div>

        <div class="row g-3 mb-3 align-items-center">
          <div class="col-auto col-xl-3 col-md-4 col-5">
            <label for="port" class="col-form-label">Database Port</label>
          </div>
          <div class="col">
            <input type="number"
                   min="0"
                   name="port"
                   id="port"
                   class="form-control"
                   placeholder="5432 or 3306 or 1433 or others"
                   required
                   v-model.number="newDatabase.port"/>
          </div>
        </div>

        <div class="row g-3 mb-3 align-items-center">
          <div class="col-auto col-xl-3 col-md-4 col-5">
            <label for="is_active" class="col-form-label">Is Active</label>
          </div>
          <div class="col">
            <select name="is_active" v-model="newDatabase.is_active"
                    id="is_active"
                    class="form-control"
                    required>
              <option :value="true">Active</option>
              <option :value="false">Inactive</option>
            </select>
          </div>
        </div>


        <div class="form-group">
          <input class="btn btn-primary float-end mt-3" type="submit" value="Update"/>
        </div>

      </form>
    </div>
  </main>
</template>