<template>
  <nav class="navbar navbar-expand-lg navbar-dark sticky-top bg-dark flex-md-nowrap shadow">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Analytic</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'conversations'}">Conversations</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'configs'}">Settings</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'examples'}">Examples</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'databases'}">Databases</router-link>
          </li>
          <!--          <li class="nav-item">-->
          <!--            <a class="nav-link" href="#">Link</a>-->
          <!--          </li>-->
          <!--          <li class="nav-item dropdown">-->
          <!--            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"-->
          <!--               aria-expanded="false">-->
          <!--              Dropdown-->
          <!--            </a>-->
          <!--            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">-->
          <!--              <li><a class="dropdown-item" href="#">Action</a></li>-->
          <!--              <li><a class="dropdown-item" href="#">Another action</a></li>-->
          <!--              <li>-->
          <!--                <hr class="dropdown-divider">-->
          <!--              </li>-->
          <!--              <li><a class="dropdown-item" href="#">Something else here</a></li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>-->
          <!--          </li>-->
        </ul>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">

</style>