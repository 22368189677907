import store from "../store";
import {Database, DatabaseTable, Example} from "../types";

// export const host = "http://localhost:3100"

export const host = "https://llm-analyst.demo.aws.yoov.dev"

export async function sendMessage(query: string) {

    const url = `${host}/api/messages/streaming?`
    let res = fetch(url + new URLSearchParams({query}), {
        method: "GET",
        headers: {
            "x-identifier": store.getters.config('identifier'),
            "x-prompt": store.getters.config('prompt'),
            "x-database": store.getters.config('database'),
        }
    });

    return {
        url,
        res
    }
}

export async function getMessages() {
    const url = `${host}/api/messages`
    let res = await fetch(url, {
        headers: {
            "x-identifier": store.getters.config('identifier'),
        }
    });
    return await res.json();
}

export async function getQueries() {
    const url = `${host}/api/examples`
    let res = await fetch(url, {
        headers: {
            "x-database": store.getters.config('database'),
        }
    });
    return await res.json();
}

export async function updateQueries(example: Example) {
    const url = `${host}/api/examples/${example.id}`
    let res = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(example), headers: {
            "Content-Type": "application/json",
            "x-database": store.getters.config('database'),
        }
    });
    return await res.json();
}

export async function getDatabases() {
    const url = `${host}/api/databases`
    let res = await fetch(url);
    return await res.json();
}

export async function postDatabase(data: Omit<Database, "id">) {
    const url = `${host}/api/databases`
    let res = await fetch(url, {
        method: "POST", body: JSON.stringify(data), headers: {
            "Content-Type": "application/json",
        }
    });
    return await res.json();

}

export async function getDatabaseTables(database: string) {
    const url = `${host}/api/databases/${database}/tables`
    let res = await fetch(url);
    return await res.json();
}

export async function updateDatabaseTable(database: string, table: DatabaseTable) {
    const url = `${host}/api/databases/${database}/tables/${table.id}`
    let res = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(table),
        headers: {
            "Content-Type": "application/json",
        }
    });
    return await res.json();
}

export async function getDatabaseTableColumns(database: string, tableId: number) {
    const url = `${host}/api/databases/${database}/tables/${tableId}/columns`
    let res = await fetch(url);
    return await res.json();
}

export async function getDatabaseTableColumn(database: string, tableId: number, columnId: string) {
    const url = `${host}/api/databases/${database}/tables/${tableId}/columns/${columnId}`
    let res = await fetch(url);
    return await res.json();
}

export async function updateDatabaseTableColumn(database: string, tableId: number, columnId: string, data: any) {
    const url = `${host}/api/databases/${database}/tables/${tableId}/columns/${columnId}`
    let res = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    });
    return await res.json();
}