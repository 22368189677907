<script setup lang="ts">

import {ref} from "vue";
import {mutations} from "@/store";
import {host} from "@/services/client";
import {useStore} from "vuex";
import {useDropZone} from '@vueuse/core'
import DocumentPreview from "@/components/UserInput/DocumentPreview.vue";


const files = ref<File[]>([])
const file_paths = ref<string[]>([])

const store = useStore()
const query = ref('')
const is_loading = ref(false)
const dropZoneRef = ref<HTMLDivElement>()
const userInput = ref<HTMLElement>()
const {isOverDropZone} = useDropZone(dropZoneRef, {
  onDrop,
  // specify the types of data to be received.
  dataTypes: ['image/png']
})

function onDrop(fs: File[] | null) {
  fs?.forEach(f => files.value.push(f))
}

const saveFilePath = (file_path: string) => {
  file_paths.value.push(file_path)
}

const removeFile = (payload: {
  name: string,
  path?: string | null
}) => {
  files.value = files.value.filter(f => f.name !== payload.name)
  if (payload.path) {
    file_paths.value = file_paths.value.filter(f => f !== payload.path)
  }
}

const focusInput = () => {
  userInput.value?.focus()
}

function ask() {
  if (query.value.length === 0) {
    return
  }
  const message = query.value
  store.commit(mutations.addTempUserMessage, message)
  const url = `${host}/api/messages/streaming?`
  const params = {
    query: query.value,
    files: file_paths.value.join(',')
  }

  fetch(url + new URLSearchParams(params), {
    method: "GET",
    headers: {
      "x-identifier": store.getters.config('identifier'),
      "x-prompt": store.getters.config('prompt'),
      "x-database": store.getters.config('database'),
    }
  })
      .then(response => {
        // Get the readable stream from the response body
        const stream = response.body;

        if (!stream) {
          return
        }

        is_loading.value = true

        store.commit(mutations.addMessage, {'role': 'assistant', 'content': ''})
        // Get the reader from the stream
        const reader = stream.getReader();
        // Define a function to read each chunk
        const readChunk = () => {
          // Read a chunk from the reader
          reader.read()
              .then((streamResponse) => {
                // Check if the stream is done
                if (streamResponse.done) {
                  // Log a message
                  console.log('Stream finished');
                  // Return from the function
                  query.value = ""
                  is_loading.value = false

                  return;
                }

                // Convert the chunk value to a string
                const chunkString = new TextDecoder().decode(streamResponse.value);
                // Log the chunk string

                const arr = chunkString.split("\n\n")
                if (arr.length) {
                  for (const txt of arr) {

                    // regex to extract event type event: <event_type>\n"
                    const event = txt.match(/event: (.*)\n/)


                    // regex to extract message type data: <message>"
                    const data = txt.match(/data: (.*)/)

                    const e = event ? event[1] : ''
                    const d = data ? data[1] : ''

                    console.log('event type: ', e)
                    console.log('message: ', d)

                    if (e === 'message') {
                      store.commit(mutations.updateLatestMessage, {
                        deltaMessage: data?.[1] || "",
                        meta: {notification: null}
                      })
                    } else if (e === 'tool') {
                      const json: any = JSON.parse(d)
                      const toolMessage = {
                        deltaMessage: "",
                        meta: {
                          ...json.meta,
                          notification: json.message,
                        }
                      }
                      console.log("toolMessage: ", toolMessage)
                      store.commit(mutations.updateLatestMessage, toolMessage)
                    }
                  }
                }

                // Read the next chunk
                readChunk();
              })
              .catch(error => {
                // Log the error
                console.error(error);
              });
        };
        // Start reading the first chunk
        readChunk();
      })

  return false
}




</script>

<template>
  <div class="position-sticky">
    <div ref="filePreview" class="filePreview text-start">
      <DocumentPreview v-for="file in files" :key="file.name"
                       :file="file"
                       @remove-file="removeFile"
                       @upload-success="saveFilePath"/>
    </div>
    <div class="files-preview"></div>
    <form action="#" class="form bottom-0" @submit.prevent="ask">
      <div class="mb-3">
        <div ref="dropZoneRef" class="dropZoneRef" @click.prevent="focusInput"><span style="color:transparent">Drop images here</span>
        </div>
        <div class="input-group mb-3">
          <input type="text" name="message" ref="userInput" class="form-control" placeholder="Your message"
                 v-model="query"
                 :disabled="is_loading">
          <button class="btn btn-outline-secondary bg-white"
                  type="submit"
                  id="button-addon2"
                  :disabled="is_loading">
            Send
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
.dropZoneRef {
  z-index: 1;
  margin: 0 40px 40px 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: block;
  //background: rgba(0, 0, 0, 0.1);
  //background-color: yellow;
}
</style>