<script setup lang="ts">


import {defineProps} from 'vue'
import {Message} from "@/store";
import {Example} from "@/types";
import * as client from "@/services/client";

type Props = {
  examples: Example[]
}

const props = defineProps<Props>()

const update = (id: number) => {

  const item = props.examples.find((i) => i.id === id)

  const data: Example = {
    id: item?.id || 0,
    query: item?.query || '',
    sql: item?.sql || '',
    is_active: item?.is_active || false
  }

  client.updateQueries(data)
      .then(() => {
        alert('updated!')
      })
}


</script>

<template>
  <table class="table">
    <thead>
    <tr>
      <th>Query</th>
      <th>SQL</th>
      <th>Is Active</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="example in examples"
        :class="{'bg-info':example.is_active}">
      <td class="text-start">{{ example.query }}</td>
      <td class="text-start">
        <textarea type="text" class="form-control w-100 col" style="min-width:50vw" rows="5"
                  v-model="example.sql"></textarea>
      </td>
      <td>
        <input type="checkbox" v-model="example.is_active">
      </td>
      <td>
        <button class="btn btn-primary" @click.prevent="update(example.id)">Update</button>
      </td>
    </tr>
    </tbody>

  </table>
</template>

<style lang="scss" scoped>
td {
  background-color: transparent !important;
}
</style>