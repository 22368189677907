<script setup lang="ts">

import {ref} from "vue";
import {
  getDatabaseTableColumn,
  getDatabaseTableColumns,
  getDatabaseTables,
  updateDatabaseTableColumn
} from "@/services/client";
import {useRoute, useRouter} from "vue-router";

const router = useRouter()
const route = useRoute()
const column = ref<{
  id: number,
  column_name: string,
  type: string,
  relationship: string,
  is_nullable: boolean,
  is_selected: boolean,
  note?: string
} | null>(null)
const databaseName = route.params.database as string
const tableId = route.params.tableId as string
const columnId = route.params.columnId as string


getDatabaseTableColumn(databaseName, parseInt(tableId), parseInt(columnId))
    .then((col) => column.value = col)


const submit = () => {
  console.log(column.value)

  updateDatabaseTableColumn(databaseName, parseInt(tableId), parseInt(columnId), column.value)
      .then(() => {
        router.push({name: 'database table columns', params: {database: databaseName, tableId}})
      })
}


</script>

<template>
  <div class="database-config">
    <h1 class="justify-content-between">Edit Database Table Column</h1>

    <form @submit.prevent="submit" class="form" v-if="column">
      <filedset>
        <legend>Update Column</legend>

        <div class="mb-3">
          <label for="column_name" class="form-label">Column Name</label>
          <input type="text" id="column_name" v-model="column.column_name" class="form-control">

        </div>
        <div class="mb-3">
          <label for="type" class="form-label">Type</label>
          <input type="text" id="type" v-model="column.type" class="form-control">
        </div>
        <div class="mb-3">
          <label for="relationship" class="form-label">Relationship</label>
          <input type="text" id="relationship" v-model="column.relationship" class="form-control">

        </div>
        <div class="mb-3">
          <label for="is_nullable" class="form-label">Is Nullable</label>
          <input type="checkbox" id="is_nullable" v-model="column.is_nullable" class="form-check-input">
        </div>
        <div class="mb-3">
          <label for="is_selected" class="form-label">Is Selected</label>
          <input type="checkbox" id="is_selected" v-model="column.is_selected" class="form-check-input">
        </div>

        <div class="mb-3">
          <label for="note" class="form-label">Note</label>
          <textarea id="note" v-model="column.note" class="form-control"></textarea>
        </div>

        <router-link :to="{name:'database table columns', params:{database:databaseName, tableId}}"
                     class="btn btn-secondary">Cancel
        </router-link>

        <button type="submit" class="btn btn-primary">Update</button>


      </filedset>

    </form>


  </div>
</template>
